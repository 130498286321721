// Generated by Framer (6f76210)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-umVu7"

const variantClassNames = {bChCveEXC: "framer-v-ies2va"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "bChCveEXC", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 2000, pixelWidth: 3000, positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/9mFT11qQYN8MkYPlLbDid4LDA.png", srcSet: "https://framerusercontent.com/images/9mFT11qQYN8MkYPlLbDid4LDA.png?scale-down-to=512 512w,https://framerusercontent.com/images/9mFT11qQYN8MkYPlLbDid4LDA.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/9mFT11qQYN8MkYPlLbDid4LDA.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/9mFT11qQYN8MkYPlLbDid4LDA.png 3000w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ies2va", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"bChCveEXC"} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-umVu7.framer-1r5d9he, .framer-umVu7 .framer-1r5d9he { display: block; }", ".framer-umVu7.framer-ies2va { height: 173px; overflow: hidden; position: relative; width: 320px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 173
 * @framerIntrinsicWidth 320
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerugkSSPlWN: React.ComponentType<Props> = withCSS(Component, css, "framer-umVu7") as typeof Component;
export default FramerugkSSPlWN;

FramerugkSSPlWN.displayName = "Card-carros";

FramerugkSSPlWN.defaultProps = {height: 173, width: 320};

addFonts(FramerugkSSPlWN, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})